<template>
  <contentBox :path="path">
    <template #content>
      <div class="content">
        <div class="content_table">
          <el-table
              :data="addressList"
              stripe
              border
              :header-cell-style="{background:'#e5e5e5',color:'#000'}"
              style="width: 100%">
            <el-table-column
                prop="name"
                :label="lang === 'en' ? 'Consignee' : '收货人'">
            </el-table-column>
            <el-table-column
                prop="mobile"
                :label="lang === 'en' ? 'Phone Number' : '手机号码'">
            </el-table-column>
            <el-table-column
                prop="address"
                :label="lang === 'en' ? 'Address' : '收货地址'">
            </el-table-column>
            <el-table-column :label="lang === 'en' ? 'operate' : '操作'" align="right">
              <template #default="scope">
                <el-button type="danger" @click="delAddress(scope.row.id)" size="mini">
                  {{ lang === 'en' ? 'Delete' : '删除' }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="footer">
          <el-button style="color:#fff;background: #C81422" @click="addressFormShow=true">+
            {{ lang === 'en' ? 'Add Address' : '新增地址' }}
          </el-button>
        </div>
        <div class="addressForm" v-if="addressFormShow">
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item :label="lang === 'en' ? '*Consignee:' : '*收货人:'">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item :label="lang === 'en' ? '*Phone:' : '*手机:'">
              <el-input v-model="form.mobile"></el-input>
            </el-form-item>
            <el-form-item :label="lang === 'en' ? '*Address:' : '*详细地址:'">
              <el-input v-model="form.address"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button style="color:#fff;background: #C81422" size="mini" @click="onAddress">
                {{ lang === 'en' ? 'Save' : '保存' }}
              </el-button>
              <el-button @click="addressFormShow=false" size="mini">{{ lang === 'en' ? 'Cancel' : '取消' }}</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>

    </template>
  </contentBox>
</template>

<script>
import contentBox from "@/components/user/content-box";
import {getAddressListApi, getAddressCreateApi, getAddressDeleteApi} from "@/api/account";
import {getLang} from "@/utils/lang";

export default {
  name: "addressPage",
  data() {
    return {
      path: '',
      checked: false,
      addressFormShow: false,
      lang: getLang(),
      form: {
        id: '',
        name: '',
        mobile: '',
        address: ''
      },
      addressList: []
    }
  },
  created() {
    this.path = this.$route.path
    this.AddressListApi()
  },
  methods: {
    init() {
      this.form = {
        id: '',
        name: '',
        mobile: '',
        address: ''
      }
      this.AddressListApi()
    },
    AddressListApi() {
      getAddressListApi().then(res => {
        this.addressList = res
      })
    },
    delAddress(id) {
      if (this.lang == 'en') {
        this.$confirm('Do you want to delete the address?', 'Delete Address', {
          confirmButtonText: 'confirm',
          cancelButtonText: 'cancel',
          type: 'warning'
        }).then(() => {
          getAddressDeleteApi({id}).then(res => {
            this.$message({
              type: 'success',
              message: 'Deletion succeeded!'
            });
            this.AddressListApi()
          })
        })
      } else {
        this.$confirm('是否要删除地址?', '删除地址', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          getAddressDeleteApi({id}).then(res => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.AddressListApi()
          })
        })
      }
    },
    onAddress() {
      if (this.lang == 'en') {
        if (this.form.name === '') {
          this.$message.error('The consignee cannot be blank');
        }
        if (this.form.mobile === '') {
          this.$message.error('Mobile number cannot be empty');
        }
        if (this.form.address === '') {
          this.$message.error('Detailed address cannot be empty');
        }
      } else {
        if (this.form.name === '') {
          this.$message.error('收货人不能为空');
        }
        if (this.form.mobile === '') {
          this.$message.error('手机号码不能为空');
        }
        if (this.form.address === '') {
          this.$message.error('详细地址不能为空');
        }
      }

      getAddressCreateApi({...this.form}).then(res => {
        if (this.lang == 'en') {
          this.$message.success('Successfully added！');
        } else {
          this.$message.success('添加成功！');
        }

        this.addressFormShow = false
        this.init()
      })
    }
  },
  components: {contentBox}
}
</script>

<style scoped>
.content {
  padding: 20px;
}

.footer {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addressForm {
  width: 400px;
  margin: 0 auto;
}
</style>
