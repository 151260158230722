<template>
  <contentBox :path="path">
    <template #content>
      <div class="content">
        <div class="top">
          <div class="top_title">
            {{ lang === 'en' ? 'Shop Cart' : '购物车' }}
          </div>
          <div class="line"></div>
        </div>
        <div class="content_table">
          <el-table
              :data="tableData"
              :header-cell-style="{background:'#e5e5e5',color:'#000'}"
              border
              stripe
              style="width: 100%"
              @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"/>
            <el-table-column
                :label="lang === 'en' ? 'Sn' : '产品编码'"
                prop="product.goodsCode">
            </el-table-column>
            <el-table-column
                v-if="lang!='en'"
                :label="lang === 'en' ? 'Product Name' : '产品名'"
                prop="product.goodsName">
            </el-table-column>
            <el-table-column v-if="lang=='en'"
                             :label="lang === 'en' ? 'Product Name' : '产品英文名'"
                             prop="product.goodsNameEn"
                             width="200">
            </el-table-column>
            <el-table-column
                :label="lang === 'en' ? 'unit-price' : '单价'"
                prop="product.marketSellPrice">
              <template #default="scope">
                {{
                  lang === 'en' ? '$' + scope.row.product.marketSellPrice : '￥' + scope.row.product.marketSellPrice
                }}
              </template>
            </el-table-column>
            <el-table-column
                :label="lang === 'en' ? 'Number' : '数量'"
                prop="number">
            </el-table-column>
            <el-table-column
                :label="lang === 'en' ? 'Price' : '金额'">
              <template #default="scope">
                {{
                  lang === 'en' ? '$' + scope.row.number * scope.row.product.marketSellPrice : '￥' + scope.row.number * scope.row.product.marketSellPrice
                }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="footer">
          <div class="footer_left">
            <el-button
                size="mini"
                type="danger"
                @click="handleDelete"
            >{{ lang === 'en' ? 'Delete' : '删除' }}
            </el-button>
            <el-button
                size="mini"
                type="primary"
                @click="handleBuy"
            >{{ lang === 'en' ? 'Buy' : '立即购买' }}
            </el-button>
          </div>
          <div class="footer_right">
            <div style="margin: 0 5px">
              {{ lang === 'en' ? 'Selected Orders：' + number + 'pieces' : '已选订单：' + number + '件' }}
            </div>
            <div style="margin: 0 5px">{{ lang === 'en' ? 'Total' : '合计:' }}</div>
            <div style="margin: 0 5px">{{ lang === 'en' ? '$' + total : '￥' + total }}</div>
          </div>
          <!--          购买弹窗-->
          <el-dialog
              :visible.sync="dialogPayment"
              :title="lang === 'en' ? 'Choose AliPay Or Wechat' : '选择支付方式:'"
              width="30%">
            <el-button type="success" @click="orderBuy('WeChat')">{{
                lang === 'en' ? 'Wechat Pay' : '微信支付:'
              }}
            </el-button>
            <el-button type="primary" @click="orderBuy('Alipay')">{{ lang === 'en' ? 'AliPay' : '支付宝支付:' }}</el-button>
            <div style="padding-top: 25px">
              {{
                lang === 'en' ? 'For corporate payment or bank transfer, please contact the customer manager at 0591-83215681.' : '如需对公支付或银行转账，请致电 0591-83215681 联系客户经理处理。:'
              }}

            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogPayment = false">{{ lang === 'en' ? 'Cancel' : '取 消' }}</el-button>
            </span>
          </el-dialog>

          <el-dialog
              :visible.sync="dialogVisible"
              :title="lang === 'en' ? 'Choose Address' : '选择地址'"
              width="30%"
          >
            <el-select v-model="value" :placeholder="lang === 'en' ? 'Please Choose' : '请选择'">
              <el-option
                  v-for="item in addressList"
                  :key="item.id"
                  :label="item.address"
                  :value="item">
              </el-option>
            </el-select>
            <span style="margin-left: 15px" @click="toAddress">{{ lang === 'en' ? 'Add Address' : '新增地址' }}</span>
            <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">{{ lang === 'en' ? 'Cancel' : '取 消' }}</el-button>
            <el-button type="primary" @click="dialogPayment = true,dialogVisible = false">{{
                lang === 'en' ? 'Confirm' : '确 定'
              }}</el-button>
            </span>
          </el-dialog>
          <el-dialog
              :visible.sync="dialogVisibles"
              :title="lang === 'en' ? 'Scan Qr' : '请扫码支付'"
              width="30%"
          >
            <div class="qc">
              <qriously :size="138" :value="baseUrl"/>
              <div>{{ lang === 'en' ? 'Please Open Wechat To Pay' : '请打开微信扫码支付' }}</div>
            </div>
            <div style="display: flex;align-items: center;justify-content: center;margin-top: 10px">
              <el-button style="margin-right: 15px" @click="toOrder">{{ lang === 'en' ? 'Cancel' : '取 消' }}</el-button>
              <el-button type="primary" @click="getOrderStatus">{{ lang === 'en' ? 'Already Pay' : '我已支付' }}</el-button>
            </div>

          </el-dialog>
        </div>
      </div>
    </template>
  </contentBox>
</template>

<script>
import contentBox from "@/components/user/content-box";
import {
  cartListApi,
  createOrderApi,
  deleteCartApi,
  getOrderPayStatusApi,
  getOrderAlipayUrlApi,
  getOrderPaymentQrApi
} from "@/api/cart";
import {getAddressListApi, mobileLoginApi} from "@/api/account";
import {getLang} from "@/utils/lang";

export default {
  name: "cart",
  data() {
    return {
      path: '',
      lang: getLang(),
      checked: false,
      dialogVisible: false,
      dialogVisibles: false,
      tableData: [],
      multipleSelection: [],
      number: 0,
      total: 0,
      deleteSelection: [],
      addressList: [],
      orderSelection: [],
      value: '',
      baseUrl: '',
      payId: 0,
      dialogPayment: false,
    }
  },
  methods: {
    getList() {
      cartListApi().then(res => {
        this.tableData = res
      })
    },
    AddressListApi() {
      getAddressListApi().then(res => {
        this.addressList = res
      })
    },
    handleSelectionChange(selection) {
      this.multipleSelection = selection
      console.log(this.multipleSelection)
    },
    handleDelete() {
      if (this.deleteSelection.length > 0) {
        deleteCartApi({id: this.deleteSelection}).then(res => {
          this.getList()
        })
      } else {
        this.$message({
          type: 'error',
          message: '请选择商品!'
        });
      }
    },
    handleBuy() {
      if (this.orderSelection.length > 0) {
        this.dialogVisible = true
      } else {
        this.$message({
          type: 'error',
          message: '请选择商品!'
        });
      }
    },
    orderBuy(mode) {
      createOrderApi({
        id: this.orderSelection,
        name: this.value.name,
        mobile: this.value.mobile,
        address: this.value.address
      }).then(res => {
        this.getList()
        this.dialogPayment = false
        this.dialogVisible = false
        this.payId = res.id
        if (mode === 'WeChat') {
          this.dialogVisibles = true
          getOrderPaymentQrApi({id: this.payId}).then(res => {
            this.baseUrl = res.paymentUrl
          })
        }
        if (mode === 'Alipay') {
          getOrderAlipayUrlApi({id: this.payId}).then(res => {
            window.location.href = res.url
          })
        }
      })
    },
    getOrderStatus() {
      getOrderPayStatusApi({id: this.payId}).then(res => {
        if (res.status == 0) {
          this.$message({
            type: 'error',
            message: '订单未支付!'
          });
        } else {
          this.$message({
            type: 'success',
            message: '支付成功'
          });
          this.$router.push("./order")
        }
      })
    },
    toOrder() {
      this.$router.push("./order")
    },
    toAddress() {
      this.$router.push("./address")
    }
  },
  watch: {
    multipleSelection(newQuestion, oldQuestion) {
      this.total = 0
      this.number = 0
      this.deleteSelection = []
      this.orderSelection = []
      for (const i of newQuestion) {
        this.total += (i.number * i.product.marketSellPrice)
        this.number += i.number
        this.deleteSelection.push(i.id)
        this.orderSelection.push(i.id)
      }
    }
  },
  created() {
    this.getList()
    this.AddressListApi()
    this.path = this.$route.path
  },
  components: {contentBox}
}
</script>

<style scoped>
.content {
  padding: 20px;
}

.top {
  position: relative;
  margin-bottom: 10px;
}

.top_title {
  width: 80px;
  padding: 10px 20px;
  text-align: center;
  background: #C81422;
  color: #fff;
}

.line {
  height: 2px;
  background: #C81422;
  width: 100%;
}

.footer {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #E5E5E5;
  margin-top: 10px;
  padding: 0 10px;
  box-sizing: border-box;
}

.footer_left, .footer_right {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.qc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
